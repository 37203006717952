@import 'src/routes/client/styles/index.scss';
@import 'src/routes/client/styles/theme';
@import 'src/routes/client/styles/mixins';

.container {
  width: 100%;
  position: relative;

  // input:-webkit-autofill{
  //   border: solid vw_d(3) $black;
  // }
  .placeholder {
    display: flex;
    align-items: center;
    pointer-events: none;
    position: absolute;
    top: 0%;
    width: 100%;
    height: vw_d(30);
    right: 0;
    font-size: vw_d(20);
    line-height: vw_d(20);
    color: $black;
    padding: 0 vw_d(14) 0 vw_d(4);
    font-family: $narkiss-regular-font;
    @include screen('mobile') {
      height: vw(30);
      padding: vw(0) vw(15);
      font-size: vw(18);
      line-height: vw(18);
    }
    b {
      position: absolute;
      right: vw_d(4);
      top: vw_d(2);
      font-size: vw_d(21);
      font-family: $narkiss-regular-font;
      font-weight: normal;
      @include screen('mobile') {
        right: vw(4);
        top: vw(2);
        font-size: vw(21);
      }
    }
  }
  .form-input {
    display: block;
    width: 100%;
    height: vw_d(30);
    padding: 0 vw_d(4);
    margin: 0;
    line-height: vw_d(30);
    font-family: $narkiss-regular-font;
    font-size: vw_d(20);
    color: $black;
    background-color: #d9d9d9;
    border: none;
    outline: none;
    border-radius: 0;
    &::placeholder {
      color: $black;
      font-family: $narkiss-regular-font;
    }

    @include screen('mobile') {
      width: 100%;
      height: vw(30);
      padding: vw(0) vw(5);
      font-size: vw(18);
    }
  }

  span {
    color: red;
    font-size: vw_d(14);
    margin-right: vw_d(5);
    margin-top: vw_d(2);
    font-family: $narkiss-regular-font;
    display: block;

    @include screen('mobile') {
      font-size: vw(14);
      margin-right: vw(5);
    }
  }
}
