@import 'src/routes/client/styles/index.scss';
@import 'src/routes/client/styles/theme';
@import 'src/routes/client/styles/mixins';

.button {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: vw_d(226);
  min-width: vw_d(226);
  height: vw_d(45);
  color: $black;
  font-size: vw_d(32);
  font-family: $narkiss-bold-font;
  background-color: #0099d8;
  @include screen('mobile') {
    width: 100%;
    height: vw(45);
    font-size: vw(32);
  }

  &:hover {
    cursor: pointer;
    opacity: 0.9;
  }

  &.disabled {
    pointer-events: none;
    background-color: #b0c4cb !important;
  }
}
