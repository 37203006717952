@import 'src/routes/client/styles/index.scss';
@import 'src/routes/client/styles/theme';
@import 'src/routes/client/styles/mixins';

header {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 4;
  @include screen('mobile') {
  }

  .header-wrapper {
    @include screen('mobile') {
      display: flex;
      justify-content: flex-start;
    }

    .logos-wrapper {
      width: vw_d(648);
      padding-top: vw_d(26);
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0 auto;
      @include screen('mobile') {
        width: auto;
        margin: 0;
        padding: vw(25) vw(16.1) 0 vw(38);
      }
      a {
        &:first-child {
          width: vw_d(196);
          margin-top: vw_d(2);
          @include screen('mobile') {
            min-width: vw(96.4);
            width: vw(96.4);
            margin-top: vw(-10);
          }
        }
        &:nth-child(2) {
          width: vw_d(74);
          margin-right: vw_d(-85);
          @include screen('mobile') {
            min-width: vw(28.5);
            width: vw(28.5);
            margin-right: vw(13);
            margin-top: vw(-3);
          }
        }
        &:last-child {
          width: vw_d(72);
          margin-top: vw_d(5);
          @include screen('mobile') {
            min-width: vw(27.7);
            width: vw(27.7);
            margin-top: 0;
            margin-right: vw(24);
          }
        }
        img {
          display: block;
          width: 100%;
        }
      }
    }
  }

  .socialls {
    position: absolute;
    left: vw_d(41);
    top: vw_d(33.5);
    display: flex;
    align-items: center;
    z-index: 2;
    @include screen('mobile') {
      left: vw(16);
      top: vw(24.4);
    }
    .social-button {
      width: vw_d(32);
      margin-right: vw_d(23);
      cursor: pointer;
      @include screen('mobile') {
        width: vw(28.6);
        margin-right: vw(11.6);
      }
      &:hover {
        opacity: 0.8;
      }
      img {
        display: block;
        width: 100%;
      }
    }
  }
}
