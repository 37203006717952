@import 'src/routes/client/styles/index.scss';
@import 'src/routes/client/styles/theme';
@import 'src/routes/client/styles/mixins';

.media-field {
  width: 100%;

  @include screen('mobile') {
    width: 100%;
  }
  &.error {
    .error-msg {
      display: block;
    }
  }
  label {
    position: relative;
    width: 100%;
    height: vw_d(30);
    cursor: pointer;
    display: flex;
    flex-wrap: wrap;
    @include screen('mobile') {
      flex-wrap: wrap;
      height: vw(30);
    }
    .wrapper {
      position: relative;
      // display: flex;
      justify-content: flex-start;
      align-items: center;
      height: 100%;
      flex-grow: 1;
      padding: 0 vw_d(14) 0 vw_d(35);
      background-color: #d9d9d9;
      font-size: vw_d(20);
      font-family: $narkiss-regular-font;
      color: $black;
      line-height: vw_d(30);

      @include screen('mobile') {
        height: 100%;
        width: 100%;
        padding: vw(9) vw(15) vw(9) vw(15);
        font-size: vw(18);
        line-height: vw(18);
        display: flex;
      }

      b {
        position: absolute;
        right: vw_d(4);
        top: vw_d(-4);
        font-size: vw_d(21);
        font-family: $narkiss-regular-font;
        font-weight: normal;
        @include screen('mobile') {
          right: vw(4);
          top: vw(2);
          font-size: vw(21);
        }
      }
      &.with-value {
        color: $black;

        width: calc(100% - #{vw_d(250)});
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        @include screen('mobile') {
          width: 100%;
          white-space: pre-wrap;
        }
      }
    }
    .btn {
      position: absolute;
      left: vw_d(2);
      top: vw_d(2);
      width: vw_d(26);
      height: vw_d(26);
      background-size: cover;
      background-position: 50% 50%;
      background-repeat: no-repeat;
      @include screen('mobile') {
        width: vw(26);
        height: vw(26);
        top: vw(2);
        left: vw(2);
        flex-shrink: inherit;
      }
    }

    input {
      display: none;
    }
  }
  .error-msg {
    position: relative;
    width: 100%;
    margin-top: vw_d(2);
    margin-right: vw_d(5);
    font-size: vw_d(14);
    font-family: $narkiss-regular-font;
    color: red;
    display: none;

    @include screen('mobile') {
      margin-top: vw(2);
      margin-right: vw(5);
      font-size: vw(14);
    }
  }
}
