@import 'src/routes/client/styles/index.scss';
@import 'src/routes/client/styles/theme';
@import 'src/routes/client/styles/mixins';

.radio {
  width: 100%;
  label {
    display: block;
    position: relative;
    cursor: pointer;
    .input-wrapper {
      position: absolute;
      right: 0;
      top: vw_d(7);
      min-width: vw_d(16);
      width: vw_d(16);
      height: vw_d(16);
      border-radius: vw_d(2);
      background-color: #fff;
      @include screen('mobile') {
        min-width: vw(25);
        width: vw(25);
        height: vw(25);
        border-radius: vw(3);
      }
      &:after {
        content: '';
        position: absolute;
        top: vw_d(4);
        left: 50%;
        transform: translate(-50%, 0%) rotate(-45deg);
        width: vw_d(10);
        height: vw_d(6);
        border: solid vw_d(2) $black;
        border-right: 0;
        border-top: 0;
        opacity: 0;
        @include screen('mobile') {
          top: vw(7);
          width: vw(16);
          height: vw(8);
          border: solid vw(4) $black;
          border-right: 0;
          border-top: 0;
        }
      }
      input {
        display: none;
      }
      &.active {
        &:after {
          opacity: 1;
        }
      }
    }

    .value {
      position: relative;
      padding-right: vw_d(29.5);
      padding-top: vw_d(7);
      font-size: vw_d(16.3);
      line-height: vw_d(16.3);
      color: #fff;
      white-space: nowrap;
      font-family: $narkiss-light-font;
      @include screen('mobile') {
        padding-top: vw(6);
        padding-right: vw(28.3);
        font-size: vw(18);
        line-height: vw(18);
      }
    }
  }
}
