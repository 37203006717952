@import 'routes/client/styles/theme.scss';
@import 'routes/client/styles/mixins.scss';
@import 'routes/client/styles/functions.scss';

.radio-btns-group {
  position: relative;
  width: 100%;
  padding-right: vw_d(5);
  @include screen('mobile') {
    padding-right: 0;
  }
  .radio-btns {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .error {
    position: absolute;
    top: vw_d(30);
    left: 50%;
    transform: translate(-50%, 0%);
    color: red;
    font-size: vw_d(14);
    font-family: $narkiss-regular-font;

    @include screen('mobile') {
      font-size: vw(14);
      top: vw(30);
    }
  }
}
