@import 'src/routes/client/styles/index.scss';
@import 'src/routes/client/styles/theme';
@import 'src/routes/client/styles/mixins';

.container {
  width: 100%;
  position: relative;
  display: flex;
  flex-flow: column nowrap;

  @include screen('mobile') {
    width: 100%;
  }
  .words-limit {
    position: absolute;
    bottom: vw_d(8);
    right: vw_d(14);
    color: $gray;
    font-family: $narkiss-regular-font;
    font-size: vw_d(18);
    font-weight: normal;

    @include screen('mobile') {
      font-size: vw(18);
      bottom: vw(10);
      right: vw(11);
    }
  }
  .placeholder {
    pointer-events: none;
    position: absolute;
    top: vw_d(4);
    width: 100%;
    right: 0;
    font-size: vw_d(20);
    color: $black;
    padding: 0 vw_d(14) 0 vw_d(4);
    font-family: $narkiss-regular-font;
    @include screen('mobile') {
      padding: vw(4) vw(15);
      font-size: vw(18);
      line-height: vw(21);
    }
    b {
      position: absolute;
      right: vw_d(4);
      top: vw_d(-4);
      font-size: vw_d(21);
      font-family: $narkiss-regular-font;
      font-weight: normal;
      @include screen('mobile') {
        right: vw(4);
        top: vw(0);
        font-size: vw(21);
      }
    }
  }
  .textarea {
    width: 100%;
    height: vw_d(72);
    padding: vw_d(4) vw_d(6) vw_d(4) vw_d(6);
    resize: none;
    font-family: $narkiss-regular-font;
    font-size: vw_d(20);
    line-height: vw_d(27);
    color: $black;
    border: none;
    border-radius: inherit;
    outline: none;
    background-color: #d9d9d9;

    @include screen('mobile') {
      width: 100%;
      height: vw(195);
      padding: vw(5) vw(5) 0 vw(13);
      font-size: vw(18);
      line-height: vw(21);
    }

    &::placeholder {
      color: $black;
    }
  }

  .error {
    margin-right: vw_d(5);
    margin-top: vw_d(2);
    font-size: vw_d(14);
    font-family: $narkiss-regular-font;
    color: red;

    @include screen('mobile') {
      margin-right: vw(5);
      font-size: vw(14);
    }
  }
}
