@import 'src/routes/client/styles/index.scss';
@import 'src/routes/client/styles/theme';
@import 'src/routes/client/styles/mixins';

.top-section {
  position: relative;
  padding: vw_d(167) 0 vw_d(16);
  background-color: transparent;
  z-index: 3;
  @include screen('mobile') {
    padding: vw(96.5) 0 vw(22);
  }

  .info {
    position: relative;
    width: vw_d(1058);
    margin: 0 auto;
    z-index: 2;
    @include screen('mobile') {
      width: 100%;
      padding: 0 vw(30) 0 vw(24);
    }
    .logo-wrapper {
      width: vw_d(218.2);
      margin: 0 auto;
      padding-bottom: vw_d(31);
      @include screen('mobile') {
        width: vw(148.4);
        padding-bottom: vw(16.6);
      }
      img {
        display: block;
        width: 100%;
        @include screen('mobile') {
          margin-right: vw(-2);
        }
      }
    }

    .text {
      text-align: right;
      @include screen('mobile') {
        width: 100%;
      }

      &.paragraph1,
      &.paragraph2,
      &.paragraph3 {
        font-size: vw_d(22);
        line-height: vw_d(26);
        letter-spacing: vw_d(0.88);
        font-family: $narkiss-bold-font;
        color: $white;
        @include screen('mobile') {
          font-size: vw(19);
          line-height: vw(23);
          letter-spacing: vw(0.76);
          font-family: $narkiss-bold-font;
        }
      }
      &.paragraph1 {
        padding-bottom: vw_d(27);
        @include screen('mobile') {
          padding-bottom: vw(24);
        }
      }
      &.paragraph2 {
        padding-bottom: vw_d(27);
        @include screen('mobile') {
          padding-bottom: vw(22);
        }
      }
      &.paragraph3 {
        padding-bottom: vw_d(16);
        @include screen('mobile') {
          padding-bottom: vw(23);
        }
      }
      &.paragraph4 {
        font-size: vw_d(21);
        line-height: vw_d(27);
        letter-spacing: vw_d(0.84);
        font-family: $narkiss-bold-font;
        color: $white;
        @include screen('mobile') {
          font-size: vw(19);
          line-height: vw(23);
          letter-spacing: vw(0.76);
        }
      }
      a {
        color: #0099d8;
      }
    }
  }
}
