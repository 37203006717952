@import 'routes/client/styles/theme.scss';
@import 'routes/client/styles/mixins.scss';
@import 'routes/client/styles/functions.scss';

.main {
  position: relative;
  min-height: 100vh;
  padding-bottom: vw_d(45);
  background-color: transparent;
  @include screen('mobile') {
    padding-bottom: vw(35.5);
  }

  .background {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: vw_d(381);
    background-size: cover;
    background-position: 50% 50%;
    z-index: 2;
  }
}
